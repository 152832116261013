const story = [
  {
    id: 1,
    img: require('../images/erdinger_radler_1.png'),

    title: 'STORY_SECTION_1_TITLE',
    description: 'STORY_SECTION_1_DESCRIPTION',
  },
  {
    id: 2,
    img: require('../images/erdinger_radler_2.png'),

    title: 'STORY_SECTION_2_TITLE',
    description: 'STORY_SECTION_2_DESCRIPTION'
  },
  {
    id: 3,
    img: require('../images/erdinger_radler_3.png'),
    title: 'STORY_SECTION_3_TITLE',
    description: 'STORY_SECTION_3_DESCRIPTION'
  },
]

export default story
