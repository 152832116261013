const events = [
  {
    head: {
      day: 'Sonntag',
      dayMobile: 'So',
      date: '30. Juni 2024',
      dateMobile: '30.06.24',
      title: 'Bad Reichenhall',
    },
    content: {
      info: '<strong><nobr>Floriani Platz,</nobr> <nobr>Bad Reichenhall</nobr></strong><br />Ab 14 Uhr<br />Eintritt frei',
      bands: [
        { title: 'Kopfeck', url: 'https://kopfeck.band/' },
        { title: 'Luegstoa C', url: 'https://www.luegstoac.band/' },
        { title: 'Gsindl', url: 'https://www.gsindl.de/' },
        { title: 'Sauerkirsch', url: 'https://sauer-kirsch.de/' },
      ],
      download: require('../images/events/event_plakat_01.png'),
      plakatUrl: '/download/RZ_EBH_FESTLTOUR_plakat_2022_X4.pdf',
      moreTitle: 'Partner',
      moreContent: [
        { title: 'RFC Bad Reichenhall (Rugbyclub)', url: 'https://rfcbadreichenhall.de/' },
      ],
    },
  },
  {
    head: {
      day: 'Sonntag',
      dayMobile: 'So',
      date: '21. Juli 2024',
      dateMobile: '21.07.24',
      title: 'Oberammergau',
    },
    content: {
      info: '<strong>Musikpavillon Oberammergau</strong><br />Ab 14 Uhr<br />Eintritt frei',
      bands: [
        { title: 'Fenzl', url: 'https://fenzlmusic.com/' },
        { title: 'Kopfeck', url: 'https://kopfeck.band/' },
        { title: 'Oimara', url: 'https://oimara-musik.de/' },
        { title: 'Monobo Son', url: 'https://www.monoboson.com/' },
      ],
      download: require('../images/events/Oberaudorf.jpg'),
      plakatUrl: '/download/RZ_EBH_FESTLTOUR_Oberaudorf_2022.pdf',
      moreTitle: 'Partner',
      moreContent: [
        { title: 'Junggesll*innenverein Oberammergau', url: 'https://jgv-ogau.de/' },
        { title: 'Ammergauer Haus', url: 'https://ammergauerhaus.de/' },
      ],
    },
  },
  {
    head: {
      day: 'Sonntag',
      dayMobile: 'So',
      date: '28. Juli 2024',
      dateMobile: '28.07.24',
      title: 'Ruhpolding',
    },
    content: {
      info: '<strong><nobr>Café Chiemgau</nobr>, <nobr>Hauptstrasse 59</nobr>, Rupolding</strong><br />Ab 14 Uhr<br />Eintritt frei',
      bands: [
        { title: 'Max von Milland', url: 'https://www.maxvonmilland.com/' },
        { title: 'Fenzl', url: 'https://fenzlmusic.com/' },
        { title: 'Oimara', url: 'https://oimara-musik.de/' },
        { title: 'Monobo Son', url: 'https://www.monoboson.com/' },
      ],
      download: require('../images/events/FESTLTOUR_ruhpolding.jpg'),
      plakatUrl: '/download/RZ_EBH_FESTLTOUR_ruhpolding_anzeige_180x250_2022_newspaper_X4.pdf',
      moreTitle: 'Partner',
      moreContent: [
        { title: 'Café Chiemgau', url: 'https://www.cafe-chiemgau.de/' },
        { title: 'Bergwacht Ruhpolding', url: 'https://www.bergwacht-bayern.de/ruhpolding.html' },
        { title: 'Wetzstoana', url: 'https://www.wetzstoana.de/' },
        { title: 'Getränke Gimpl', url: 'https://www.getraenke-gimpl.net/' },
      ],
    },
  },
  {
    head: {
      day: 'Sonntag',
      dayMobile: 'So',
      date: '01. September 2024',
      dateMobile: '01.09.24',
      title: 'Erding',
    },
    content: {
      info: '<strong>Weissbräuzelt, <nobr>Herbstfest Erding</nobr></strong><br />Ab 13 Uhr<br />Eintritt frei',
      bands: [
        { title: 'DeSchoWieda', url: 'https://deschowieda.de/' },
        { title: 'Dis M', url: 'https://www.dis-m.net/' },
        { title: 'Saustoimusi', url: 'https://www.saustoimusi.de/' },
        { title: 'Oimara und Band', url: 'https://oimara-musik.de/' },
      ],
      download: require('../images/events/event_plakat_01.png'),
      plakatUrl: '/download/RZ_EBH_FESTLTOUR_plakat_2022_X4.pdf',
      moreTitle: 'Partner',
      moreContent: [
        { title: 'Festwirt Stiftl', url: 'https://www.stiftl.de/gastronomie/index.php' },
      ],
    },
  },
]

export default events
