import React from 'react'

const ChevronSvg = ({ fill = '#ffffff' }) => {
  return (
    <svg width={12} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m.987 19.385 9.066-9.383-8.646-8.947"
        stroke={`${fill}`}
        strokeWidth={2}
      />
    </svg>
  )
}

export default ChevronSvg
