import React from 'react'
import tw from 'twin.macro'
import { useBreakpoint } from '@hooks/useBreakpoint'
import Story from '../elements/Story'

const Section = tw.div`lg:pt-[150px] lg:pb-20 bg-white pt-[100px]`

const StoriesSection = ({ content }) => {
  const { isTablet } = useBreakpoint()

  return (
    <Section>
      {content.map(({ id, title, description, img }) => {
        return (
          <Story
            key={id}
            title={title}
            description={description}
            img={img}
            variant={id % 2 === 0 && !isTablet ? 'right' : 'left'}
          />
        )
      })}
    </Section>
  )
}

export default StoriesSection
