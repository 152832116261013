import React from 'react'
import { motion } from 'framer-motion'

const MarkerSvg = (props, { className }) => (
  <motion.svg
    width={42}
    height={67}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M21.196 63.274 4.276 28.815C.74 21.595 2.163 12.88 7.85 7.192c7.366-7.366 19.29-7.366 26.655 0 5.689 5.688 7.148 14.403 3.574 21.623L21.196 63.274Z"
      fill="#fff"
      stroke={props.stroke}
      strokeWidth={2.84}
      strokeMiterlimit={10}
    />
  </motion.svg>
)

export default MarkerSvg
