import * as React from 'react'
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { notSafari } from '../modules/LandingHeader'

const groupVariants01 = {
  initial: {
    strokeOpacity: 0.2,
  },
  animate: {
    strokeOpacity: 1,
    transition: {
      delay: 5,
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 2,
      staggerChildren: 0.5,
      delayChildren: 0.5,
      repeatDelay: 4,
    },
  },
}
const groupVariants02 = {
  init: {
    strokeOpacity: 0.2,
  },
  animate: {
    strokeOpacity: 1,
    transition: {
      delayChildren: 4,
      repeat: Infinity,
      duration: 2,
      staggerChildren: 0.5,
      repeatDelay: 4,
    },
  },
}
const groupVariants03 = {
  init: {
    strokeOpacity: 0.2,
  },
  animate: {
    strokeOpacity: 1,
    transition: {
      duration: 2,
      staggerChildren: 0.5,
      delayChildren: 10,
      repeatDelay: 4,
    },
  },
}
const groupVariants04 = {
  init: {
    strokeOpacity: 0.2,
  },
  animate: {
    strokeOpacity: 1,
    transition: {
      delayChildren: 15,
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 2,
      staggerChildren: 0.5,
      repeatDelay: 4,
    },
  },
}
const pathVariants = {
  initial: {
    strokeOpacity: 0.2,
  },
  animate: {
    strokeOpacity: [0.2, 1, 0.2],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatDelay: 2,
    },
  },
}

const BackgroundSvg = (props) => {
  const { isTablet } = useBreakpoint()
  const mobileSafari = isTablet && !notSafari
  return (
    <motion.svg
      width={1671}
      height={819}
      viewBox="0 0 1671 819"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_58_5757"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={-1}
        y={0}
        width={1672}
        height={819}
      >
        <rect
          x={-0.000732422}
          y={0.0112305}
          width={1671}
          height={818.517}
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_58_5757)">
        <g opacity={0.4}>
          <g id="background_strings_wrap" clipPath="url(#clip0_58_5757)">
            <motion.g
              variants={groupVariants01}
              animate={props.animate ? 'animate' : 'initial'}
            >
              <motion.path
                d="M-1460.79 -268.208L3561.92 1078.43"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M-1517.39 -1.77543L3618.48 812.123"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M-1545.81 269.041L3646.9 541.306"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M-1545.8 541.274L3646.93 268.945"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
            </motion.g>
            <motion.path
              d="M-1517.45 812.141L3618.54 -1.79375"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M-1460.86 1078.44L3561.95 -268.096"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M-1376.7 1337.52L3477.79 -527.172"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M-1266.05 1586.18L3367.14 -775.837"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.g
              variants={groupVariants03}
              animate={!mobileSafari ? 'animate' : 'initial'}
            >
              <motion.path
                d="M-1129.92 1822.09L3231.01 -1011.75"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M-970.029 2042.31L3071.12 -1231.97"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M-787.877 2244.78L2888.97 -1434.43"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M-585.685 2426.97L2686.78 -1616.62"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
            </motion.g>
            <motion.path
              d="M-365.552 2586.94L2466.51 -1776.62"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M-129.809 2723.09L2230.9 -1912.75"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M118.794 2833.88L1982.3 -2023.53"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M377.565 2918.01L1723.4 -2107.7"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.g
              variants={groupVariants04}
              animate={!mobileSafari ? 'animate' : 'initial'}
            >
              <motion.path
                d="M643.839 2974.65L1457.25 -2164.3"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M914.494 3003.08L1186.6 -2192.73"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M1186.56 3003.07L914.398 -2192.76"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M1457.27 2974.7L643.82 -2164.36"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
            </motion.g>
            <motion.path
              d="M1723.41 2918.08L377.677 -2107.73"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M1982.33 2833.87L118.757 -2023.52"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M2230.85 2723.15L-129.76 -1912.8"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M2466.62 2586.95L-365.529 -1776.6"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.g
              variants={groupVariants02}
              animate={!mobileSafari ? 'animate' : 'initial'}
            >
              <motion.path
                d="M2686.71 2426.96L-585.617 -1616.61"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M2889.05 2244.7L-787.961 -1434.35"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M3071.13 2042.38L-970.04 -1232.04"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
              <motion.path
                d="M3231.04 1821.99L-1129.91 -1011.77"
                stroke="#fff"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeOpacity={0.2}
                variants={pathVariants}
              />
            </motion.g>
            <motion.path
              d="M3367.08 1586.23L-1265.99 -775.886"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
            <motion.path
              d="M3477.8 1337.48L-1376.71 -527.134"
              stroke="#fff"
              strokeWidth={2}
              strokeMiterlimit={10}
              strokeOpacity={0.2}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_58_5757">
          <rect
            width={5200.1}
            height={5202.79}
            fill="white"
            transform="matrix(-0.965887 -0.258963 0.258675 -0.965964 2888.98 3591.35)"
          />
        </clipPath>
      </defs>
    </motion.svg>
  )
}

export default BackgroundSvg
