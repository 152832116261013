import React from 'react'
import tw from 'twin.macro'
import { motion } from 'framer-motion'

const StyledSubHeadline = tw(
  motion.h2
)`relative z-30 font-lobster text-white text-160px`

const SubHeadline = ({ label, className, ...props }) => {
  if (!label) return null
  return (
    <StyledSubHeadline className={`${className}`} key="modal" {...props}>
      {label}
    </StyledSubHeadline>
  )
}

export default SubHeadline
