import React from 'react'
import tw, { styled } from 'twin.macro'
import ytPoster1 from '@images/popup-poster.png'
import ytPoster2 from '@images/yt-poster-01.jpeg'
import ytPoster2EN from '@images/yt-poster-01_EN.jpg'
import { Link } from 'gatsby'
import { useStateContext } from '@context/stateContext'
import { motion } from 'framer-motion'
import {I18nextContext} from "gatsby-plugin-react-i18next";

const Section = tw.section`lg:mx-8 lg:px-2.5 relative pb-2 pt-10 xs:pt-20 xs:pb-10`
const VideoWrap = tw.div`relative  max-w-master mx-auto w-full px-5 lg:px-0`
const IframeWrap = styled.div`
  ${tw`relative overflow-hidden`}
  aspect-ratio: 16 / 9;

  .play {
    ${tw`ml-2 transition duration-300 w-[25px] h-[25px] border-t-[15px] border-r-0 border-b-[15px] border-l-[25px] sm:w-[45px] sm:h-[45px] sm:border-t-[30px] sm:border-r-0 sm:border-b-[30px] sm:border-l-[45px]`}
    border-color: transparent transparent transparent #006e42;
  }
  .wrap-play {
    ${tw`absolute flex items-center justify-center transition duration-300 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full shadow-2xl cursor-pointer w-18 h-18 sm:w-28 sm:h-28 top-1/2 left-1/2`}

    &:hover {
      ${tw`bg-radler`}

      .play {
        border-color: transparent transparent transparent #fff;
      }
    }
  }
`
const ImageHeadlineWrap = tw.div`relative`
const Headline = tw.div`px-5 text-center text-2xl md:text-4xl font-lobster text-gray tracking-[0.02em] pb-4 lg:pb-10`
const CookieNotification = tw(
  motion.div
)`absolute bottom-0 left-0 right-0 bg-helles w-full px-5 max-w-master mx-auto py-5 text-white flex flex-col xs:flex-row justify-between items-center text-xs`
const CookieText = tw.div`text-white`
const ConsentButton = tw.div`bg-radler text-white text-xs md:text-15px mt-3 xs:mt-0 px-2 md:px-4 py-1 md:py-2 rounded cursor-pointer`
const ConsentDeclineButton = tw.div`bg-white text-radler text-xs md:text-15px mt-3 xs:mt-0 px-2 md:px-4 py-1 md:py-2 rounded cursor-pointer`

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const VideoSection = () => {
  const { ytConsent, setYtConsent } = useStateContext()
  const [showNotification1, setShowNotification1] = React.useState(false)
  const [showNotification2, setShowNotification2] = React.useState(false)
  React.useEffect(() => {
    const cookieValue = getCookie('youtube-consent')
    setYtConsent(cookieValue)
  }, [ytConsent])
  const { language } = React.useContext(I18nextContext)

  return (
    <Section>
      <ImageHeadlineWrap>
        <Headline>So hört sich‘s an</Headline>
      </ImageHeadlineWrap>
      <VideoWrap className="mb-20">
        <IframeWrap className="aspect-w-16 aspect-h-9">
          {(ytConsent === 'false' || !ytConsent) && (
            <>
              <img
                className="w-full h-full"
                src={ytPoster1}
                alt="Das ERDINGER Brauhaus präsentiert den neuen Song “Mit dir lafft’s” von DeSchoWieda!"
              />
              <div
                className="wrap-play"
                onClick={() => setShowNotification1(true)}
              >
                <div className="play"></div>
              </div>
              {showNotification1 && (ytConsent === 'false' || ytConsent === '') && (
                <CookieNotification
                  initial={{ y: '100%' }}
                  animate={{ y: 0 }}
                  transition={{ duration: 0.5, type: 'tween' }}
                >
                  <CookieText>
                    Ich möchte YouTube-Inhalte aktivieren und stimme zu, dass
                    Daten von YouTube geladen werden. Zu den{' '}
                    <Link className="inline-block underline" to="/datenschutz/">
                      Datenschutzhinweisen
                    </Link>
                    .
                  </CookieText>
                  <ConsentButton
                    onClick={() => {
                      setYtConsent('true')
                      setCookie('youtube-consent', true, 356)
                    }}
                  >
                    Akzeptieren
                  </ConsentButton>
                </CookieNotification>
              )}
            </>
          )}
          {ytConsent === 'true' && (
            <iframe
              className="w-full h-full mb-2"
              title={`Das ERDINGER Brauhaus präsentiert den neuen Song “Mit dir lafft’s” von DeSchoWieda!`}
              src={`https://www.youtube-nocookie.com/embed/hhyyhpsjdvg?rel=0`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          )}
        </IframeWrap>
      </VideoWrap>
      <VideoWrap>
        <IframeWrap className="aspect-w-16 aspect-h-9">
          {(ytConsent === 'false' || !ytConsent) && (
            <>
              <img
                className="w-full h-full"
                src={language === 'en' ? ytPoster2EN : ytPoster2}
                alt="ERDINGER Brauhaus - RadioSpot"
              />
              <div
                className="wrap-play"
                onClick={() => setShowNotification2(true)}
              >
                <div className="play"></div>
              </div>
              {showNotification2 && (ytConsent === 'false' || ytConsent === '') && (
                <CookieNotification
                  initial={{ y: '100%' }}
                  animate={{ y: 0 }}
                  transition={{ duration: 0.5, type: 'tween' }}
                >
                  <CookieText>
                    Ich möchte YouTube-Inhalte aktivieren und stimme zu, dass
                    Daten von YouTube geladen werden. Zu den{' '}
                    <Link className="inline-block underline" to="/datenschutz/">
                      Datenschutzhinweisen
                    </Link>
                    .
                  </CookieText>
                  <ConsentButton
                    onClick={() => {
                      setYtConsent('true')
                      setCookie('youtube-consent', true, 356)
                    }}
                  >
                    Akzeptieren
                  </ConsentButton>
                </CookieNotification>
              )}
            </>
          )}
          {ytConsent === 'true' && (
            <iframe
              className="w-full h-full"
              title={`ERDINGER Brauhaus - RadioSpot`}
              src={`https://www.youtube-nocookie.com/embed/EUr6iksFIXg?rel=0`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          )}
        </IframeWrap>
      </VideoWrap>
    </Section>
  )
}
export default VideoSection
