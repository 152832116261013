import React from 'react'
import tw, { styled } from 'twin.macro'
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledStory = tw.div`flex flex-col lg:flex-row sm:justify-between sm:pb-[150px] pb-[100px] max-w-screen-2xl mx-auto lg:px-20 px-5`

const LeftColumn = styled.div`
  ${tw`flex flex-col items-center justify-center flex-1 lg:justify-start sm:flex-row sm:items-start `}
`

const RightColumn = styled.div`
  ${tw`flex flex-col items-center justify-center flex-1 sm:flex-row sm:items-start `}
`

const Image = styled.img`
  ${tw`w-full`}
`

const Story = ({ img, description, title, variant = 'right' }) => {
  const { t } = useTranslation()
  return (
    <StyledStory>
      <LeftColumn
        className={`${
          variant === 'right'
            ? 'lg:order-2 lg:justify-end lg:ml-[25px]'
            : 'lg:justify-start lg:mr-[25px]'
        }`}
      >
        <div tw="flex items-center h-full w-full sm:w-auto  mb-5 lg:mb-0">
          <Image src={img.default} alt={title} />
        </div>
      </LeftColumn>
      <RightColumn>
        <div tw="flex flex-col justify-center h-full">
          <p tw="text-gray font-bold mb-3.5 lg:mb-12 font-Blackriver lg:text-[32px] lg:leading-[42px] text-[18px] leading-[23px] lg:tracking-wide">
            {t(title)}
          </p>
          <p tw="text-15px leading-[18px] text-gray font-roboto sm:text-18px max-w-[615px] lg:tracking-wide">
            {t(description)}
          </p>
        </div>
      </RightColumn>
    </StyledStory>
  )
}

export default Story
