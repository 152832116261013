const sections = {
  de: [
    {
      id: 0,
      type: 'radler',
      logo: require('../images/radler-logo.svg'),
      logoText: 'Fruchtig-frisches Natur Radler',
      textBar: 'Mit fruchtig-frischer "Wild-Zitrone"',
      textBarMobile: 'Mit fruchtig-frischer "Wild-Zitrone"',
      heading: 'Natürlich. Echt. Wuid. <br> Kleine Erzischung gefällig?',
      list: [
        'Spritzig, fruchtig und nicht zu süß',
        'Naturtrüb mit 50% Wild-Zitrone und 50% <br> herrlichem Hellen',
        'Bei 6 Grad erreicht das Zischometer sein Optimum <br>',
        'Einfach a bisserl besser',
      ],
      listMobile: [
        'Spritzig, fruchtig und nicht zu süß',
        'Naturtrüb mit 50% Wild-Zitrone und 50% herrlichem Hellen',
        'Bei 6 Grad erreicht das Zischometer sein Optimum',
        'Einfach a bisserl besser',
      ],
      beer: require('../images/landing/EBH_RADLER_0.png'),
      beerMobile: require('../images/EBH_RADLER_1.png'),
      beerText: 'ERDINGER Brauhaus Natur Radler',
      lemon: require('../images/landing/zitrone_02_mirror.png'),
      lemonText: 'Zitrone',
      contentImage: require('../images/radler-image.png'),
      contentImageText: 'Fruchtig-frisches Natur Radler',
      tb01: require('../images/tb_radler_01.svg'),
      tb02: require('../images/tb_radler_02.svg'),
      tb03: require('../images/tb_radler_04.svg'),
      tb04: require('../images/tb_radler_03.svg'),
      tb01Text: 'Mischung 50/50 - Alc. Vol. 2.6%',
      tb02Text: 'Frisch',
      tb03Text: 'Brennwert 42* kcal',
      tb04Text: 'Läuft ideal bei 6°C',
      badge: require('../images/radler_badge.svg'),
      badgeText: 'Einfach a bisserl besser!',
      markerDefault: '530%',
      markerMobile: '520%',
      color: '#006E42',
      bottomTitle: "Und so steht's im Gschäft",
      b01: require('../images/b_radler_01.png'),
      b02: require('../images/b_radler_02.png'),
      b03: require('../images/b_radler_03.png'),
      b05: require('../images/b_radler_05.png'),
      b01Text: 'ERDINGER Brauhaus Natur Radler Kasten',
      b02Text: 'ERDINGER Brauhaus Natur Radler Sixpack 0,33l',
      b03Text: 'ERDINGER Brauhaus Natur Radler Sixpack 0.5l',
      b05Text: 'ERDINGER Brauhaus Natur Radler Dose 0.5l',
      hop: require('../images/hop_radler.png'),
      hopText: 'Hopfen',
      calories: '*NaturRadler: Brennwert pro 100 ml: 163 kJ / 39 kcal',
    },
    {
      id: 1,
      type: 'helles',
      logo: require('../images/helles-logo.svg'),
      logoText: 'Original Helles Lagerbier',
      textBar:
        '"Traunstein und Erding, Vilshofen und Schärding <br> sind in Bayern der Orte vier, wo man braut das beste Bier."',
      textBarMobile:
        '"Traunstein und Erding, Vilshofen und Schärding <br> sind in Bayern der Orte vier, wo man braut das beste Bier."',
      heading: "Schluck für Schluck ein Genuss <br> Mei is' des süffig!",
      list: [
        'Herrlich mild mit süffigem Charakter',
        'Läuft am Besten bei 8 Grad',
        'Wertvolle Zutaten und beste Braumeisterqualität',
        'Aus der Bierstadt Erding',
      ],
      listMobile: [
        'Herrlich mild mit süffigem Charakter',
        'Läuft am Besten bei 8 Grad',
        'Wertvolle Zutaten und beste Braumeisterqualität',
        'Aus der Bierstadt Erding',
      ],
      beer: require('../images/landing/EBH_HELLES_0.png'),
      beerMobile: require('../images/landing/EBH_HELLES_0.png'),
      beerText: 'Original Helles Lagerbier',
      contentImage: require('../images/helles-image.png'),
      contentImageText: 'Original Helles Lagerbier',
      tb01: require('../images/tb_helles_01.svg'),
      tb02: require('../images/tb_helles_02.svg'),
      tb03: require('../images/tb_helles_04.svg'),
      tb04: require('../images/tb_helles_03.svg'),
      tb01Text: 'Charakter Süffig - Stammwürze 11.8°P - Alc. Vol. 5.1%',
      tb02Text: 'Mild Herb',
      tb03Text: 'Brennwert 42** kcal',
      tb04Text: 'Läuft ideal bei 8°C',
      badge: require('../images/helles_badge.svg'),
      badgeText: 'Aus Liebe zum Bier!',
      markerDefault: '200%',
      markerMobile: '200%',
      color: '#004580',
      bottomTitle: "Und so steht's im Gschäft",
      b01: require('../images/b_helles_01.png'),
      b02: require('../images/b_helles_02.png'),
      b03: require('../images/b_helles_03.png'),
      b05: require('../images/b_helles_05.png'),
      b01Text: 'ERDINGER Brauhaus Helles Kasten',
      b02Text: 'ERDINGER Brauhaus Helles Sixpack 0,33l',
      b03Text: 'ERDINGER Brauhaus Helles Sixpack 0.5l',
      b05Text: 'ERDINGER Brauhaus Helles Dose 0.5l',
      hop: require('../images/hop_helles.png'),
      hopText: 'Hopfen',
      calories: '**Helles: Brennwert pro 100 ml: 176 kJ / 42 kcal',
    },
  ],
  en: [
    {
      id: 2,
      type: 'radler',
      logo: require('../images/radler-logo_EN.svg'),
      logoText: 'Fruity-fresh Natur Radler',
      textBar: 'With fruity-fresh lemon',
      textBarMobile: 'With fruity-fresh lemon',
      heading: 'Natural. Authentic. <br> Would you like some refreshment?',
      list: [
        'Fizzy, fruity and not too sweet',
        'Naturally cloudy with 50% lemons and 50% <br> heavenly Helles',
        'Serve at 6 degrees for maximum taste sensation <br>',
        'Simply just a little bit better',
      ],
      listMobile: [
        'Fizzy, fruity and not too sweet',
        'Naturally cloudy with 50% lemons and 50% heavenly Helles',
        'Serve at 6 degrees for maximum taste sensation',
        'Simply just a little bit better',
      ],
      beer: require('../images/landing/EBH_RADLER_0.png'),
      beerMobile: require('../images/EBH_RADLER_1.png'),
      beerText: 'ERDINGER Brauhaus Natur Radler',
      lemon: require('../images/landing/zitrone_02_mirror.png'),
      lemonText: 'Zitrone',
      contentImage: require('../images/radler-image.png'),
      contentImageText: 'Fruchtig-frisches Natur Radler',
      tb01: require('../images/tb_radler_01_EN.svg'),
      tb02: require('../images/tb_radler_02_EN.svg'),
      tb03: require('../images/tb_radler_04_EN.svg'),
      tb04: require('../images/tb_radler_03_EN.svg'),
      tb01Text: 'Mixture 50/50 - Alc. Vol. 2.6%',
      tb02Text: 'Fresh',
      tb03Text: 'Calories 42* kcal',
      tb04Text: 'Best served at 6°C',
      badge: require('../images/radler_badge_EN.svg'),
      badgeText: 'Simply just a little bit better!',
      markerDefault: '530%',
      markerMobile: '520%',
      color: '#006E42',
      bottomTitle: "This is how you can find our bundle to buy",
      b01: require('../images/b_radler_01.png'),
      b04: require('../images/b_radler_04.png'),
      b05: require('../images/b_radler_05.png'),
      b01Text: 'ERDINGER Brauhaus Natur Radler Kasten',
      b02Text: 'ERDINGER Brauhaus Natur Radler Sixpack 0,33l',
      b03Text: 'ERDINGER Brauhaus Natur Radler Sixpack 0.5l',
      b04Text: 'ERDINGER Brauhaus Natur Radler 12pack 0.5l',
      b05Text: 'ERDINGER Brauhaus Natur Radler Dose 0.5l',
      hop: require('../images/hop_radler.png'),
      hopText: 'Hopfen',
      calories: '*NaturRadler: Calories per 100 ml: 163 kJ / 39 kcal',
    },
    {
      id: 3,
      type: 'helles',
      logo: require('../images/helles-logo_EN.svg'),
      logoText: 'Original Helles Lagerbier',
      textBar:
        'A passion for Bavarian brewing tradition',
      textBarMobile:
        'A passion for Bavarian brewing tradition',
      heading: "Every sip tastes so good <br> Wow is that smooth!",
      list: [
        'Incredibly mild with a smooth finish',
        'Best served at 8 degrees',
        'The best brewing skills using the highest quality ingredients',
        'From the brewing town of Erding',
      ],
      listMobile: [
        'Incredibly mild with a smooth finish',
        'Best served at 8 degrees',
        'The best brewing skills using the highest quality ingredients',
        'From the brewing town of Erding',
      ],
      beer: require('../images/landing/EBH_HELLES_0.png'),
      beerMobile: require('../images/landing/EBH_HELLES_0.png'),
      beerText: 'Original Helles Lagerbier',
      contentImage: require('../images/helles-image.png'),
      contentImageText: 'Original Helles Lagerbier',
      tb01: require('../images/tb_helles_01_EN.svg'),
      tb02: require('../images/tb_helles_02_EN.svg'),
      tb03: require('../images/tb_helles_04_EN.svg'),
      tb04: require('../images/tb_helles_03_EN.svg'),
      tb01Text: 'Character Smooth - Stammwürze 11.8°P - Alc. Vol. 5.1%',
      tb02Text: 'Mild Herb',
      tb03Text: 'Calories 42** kcal',
      tb04Text: 'Best served at 8°C',
      badge: require('../images/helles_badge_EN.svg'),
      badgeText: 'For the love of beer!',
      markerDefault: '530%',
      markerMobile: '520%',
      color: '#004580',
      bottomTitle: "This is how you can find our bundle to buy",
      b01: require('../images/b_helles_01.png'),
      b04: require('../images/b_helles_04.png'),
      b05: require('../images/b_helles_05.png'),
      b01Text: 'ERDINGER Brauhaus Helles Kasten',
      b02Text: 'ERDINGER Brauhaus Helles Sixpack 0,33l',
      b03Text: 'ERDINGER Brauhaus Helles Sixpack 0.5l',
      b04Text: 'ERDINGER Brauhaus Helles 12pack 0.5l',
      b05Text: 'ERDINGER Brauhaus Helles Dose 0.5l',
      hop: require('../images/hop_helles.png'),
      hopText: 'Hopfen',
      calories: '**Helles: Calories per 100 ml: 176 kJ / 42 kcal',
    },
  ]
}

export default sections
