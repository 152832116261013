const glassSection = {
  de: [
    {
      headline: 'Im Glasl schmeckts am Besten',
      subheadline: 'Auf geht\'s hol dir dei <br/> Erdinger Brauhaus Glasl!',
    },
  ],
  en: [
    {
      headline: 'Tastes best in a glass',
      subheadline: 'Come and get your <br/> Erdinger Brauhaus glass!',
    },
  ]
}

export default glassSection
