import React from 'react'
import tw from 'twin.macro'
import { motion } from 'framer-motion'

const StyledHeadline = tw(
  motion.h1
)`relative z-30 font-lobster text-white text-160px`

const Headline = ({ label, className, ...props }) => {
  if (!label) return null
  return (
    <StyledHeadline className={`${className}`} key="modal" {...props}>
      {label}
    </StyledHeadline>
  )
}

export default Headline
